import { useState, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Menu,
  Sidebar,
} from "semantic-ui-react";
import SimpleBar from "simplebar-react";
import { authState } from "../../atoms/authState";
import useResponsive from "../../hooks/useResponsive";
import SidebarProfileBox from "./SidebarProfileBox";
import SimpleFooter from "./SimpleFooter";
import * as authService from "../../services/authService";
import SidebarMenu from "./SidebarMenu";

function DashboardLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [authStore, setAuthStore] = useRecoilState(authState);

  const isMobile = useResponsive("(max-width: 767px)");
  const location = useLocation();

  const handleLogOut = () => {
    authService.removeUserData();
    setAuthStore(null);
  };

  useEffect(() => {
    if (!isMobile) {
      setSidebarOpen(true);
    }
    return () => {};
  }, [isMobile]);

  return (
    <div>
      <Sidebar
        visible={sidebarOpen}
        onHide={() => {
          if (!isMobile) return;
          setSidebarOpen(false);
        }}
        animation="overlay"
        vertical
        style={{
          overflowY: "hidden !important",
          backgroundColor: "#3f3d56",
          color: "white",
        }}
      >
        <SimpleBar style={{ maxHeight: "100vh", backgroundColor: "#3f3d56" }}>
          <SidebarProfileBox />
          <SidebarMenu handleLogOut={handleLogOut} />
        </SimpleBar>
      </Sidebar>
      <div style={{ marginLeft: isMobile ? "0px" : "260px" }}>
        <Grid>
         {/*  <GridRow>
            <GridColumn></GridColumn>
          </GridRow> */}
          <GridRow style={{ padding: isMobile ? "4rem .5rem" : "0rem 2rem" }}>
            <GridColumn className="page-wrapper">
              {isMobile && (
                <Menu
                  borderless
                  size="huge"
                  fixed="top"
                  inverted
                  style={{
                    paddingLeft: isMobile ? "0px" : "260px",
                    backgroundColor: "#3f3d56",
                    boxShadow: "rgba(67, 117, 151, 0.08) 0px 3px 7px 0px",
                  }}
                >
                  <Menu.Item icon="book" name="kontbox" />
                  <Menu.Menu position="right">
                    <Menu.Item
                      as="a"
                      onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                      <Icon name="bars" circular />
                    </Menu.Item>
                  </Menu.Menu>
                </Menu>
              )}
              <div style={{ minHeight: "100vh" }}>
                <Outlet />
              </div>
              <SimpleFooter />
            </GridColumn>
          </GridRow>
        </Grid>
      </div>
    </div>
  );
}

export default DashboardLayout;
