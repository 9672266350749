import { api } from "./api";

export const login = async (userData) => {
  return api.post("users/login", userData);
};

export const register = async (userData) => {
  return api.post("users/register", userData);
};

export const changePassword = async (userData) => {
  return api.put("users/change-password", userData);
};

export const removeUserData = () => {
  localStorage.removeItem("kontbox@user");
};

export const saveUserData = (userData) => {
  localStorage.setItem("kontbox@user", JSON.stringify(userData));
};

export const getUserFromStorage = () => {
  return localStorage.getItem("kontbox@user");
};

export const saveUserFromStorage = (userData) => {
  return localStorage.getItem("kontbox@user", JSON.stringify(userData));
};
