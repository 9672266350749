import "./bck.css";
import dayjs from "dayjs";
import { useState, useEffect, useRef } from "react";
import {
  Link,
  unstable_HistoryRouter,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  List,
  Loader,
  Message,
  Segment,
  Sticky,
} from "semantic-ui-react";
import Page from "../../components/shared/Page";
import useResponsive from "../../hooks/useResponsive";
import { getBackendRoute } from "../../services/api";
import { getBookBySlug, likeBook } from "../../services/bookService";
import relativeTime from "dayjs/plugin/relativeTime";
import BookSuggestions from "../../components/shared/BookSuggestions";
dayjs.extend(relativeTime);

const BookDetails = () => {
  const isMobile = useResponsive("(max-width: 767px)");
  const [book, setBook] = useState();
  const { slug } = useParams();
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchBookBySlug = async () => {
    try {
      setLoading(true);
      const response = await getBookBySlug(slug);
      if (response.status === 200) {
        setBook(response.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // fonction qui utilise le service likeBook
  const handleStarClick = async () => {
    try {
      const response = await likeBook(book.id);
      if (response.status === 200) {
        fetchBookBySlug();
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchBookBySlug();

    return () => {};
  }, [slug]);

  useEffect(() => {
    return () => {
      if ("speechSynthesis" in window) {
        window.speechSynthesis.cancel();
      }
    };
  }, []);

  return (
    <Page title={`${book?.slug}`}>
      <Grid stackable>
        <Grid.Column width="10">
          <Container>
            <Grid>
              <Grid.Column width="16">
                <Segment>
                  {loading ? (
                    <Loader active inline="centered">
                      Chargement en cours...
                    </Loader>
                  ) : (
                    <Grid>
                      <Grid.Column width="6">
                        <Image
                          src={`${getBackendRoute()}images/books/${
                            book?.image
                          }`}
                          rounded
                          style={{ cursor: "pointer" }}
                        />
                      </Grid.Column>
                      <Grid.Column width="10" style={{}}>
                        <Header style={{ marginBottom: 10 }} as="h4">
                          {book?.name}
                        </Header>
                        {!isMobile && (
                          <>
                            <div>
                              <Label as="a">
                                <Icon name="eye" />
                                {book?._count?.Views}
                              </Label>
                              <Label as="a" onClick={handleStarClick}>
                                <Icon name="star" />
                                {book?._count?.Stars}
                              </Label>
                              <Label as="a">
                                <Icon name="numbered list" />
                                {book?._count?.Chapter || 0}
                              </Label>
                            </div>
                            <div style={{ marginTop: 15 }}>
                              <Button
                                circular
                                color="yellow"
                                icon="book"
                                content="Lire Maintenant"
                                as={Link}
                                to={`/books/read/${book?.slug}`}
                              />
                              <Button circular icon="heart outline" />
                            </div>
                          </>
                        )}
                      </Grid.Column>
                    </Grid>
                  )}
                </Segment>
                {isMobile &&
                  (loading ? (
                    <Loader active inline="centered">
                      Chargement en cours...
                    </Loader>
                  ) : (
                    <div
                      className="ui segment"
                      style={{ marginTop: 10, textAlign: "center" }}
                    >
                      <div>
                        <Label as="a">
                          <Icon name="eye" />
                          {book?._count?.Views}
                        </Label>
                        <Label as="a" onClick={handleStarClick}>
                          <Icon name="star" />
                          {book?._count?.Stars}
                        </Label>
                        <Label as="a">
                          <Icon name="numbered list" />
                          {book?._count?.Chapter || 0}
                        </Label>
                      </div>
                      <div style={{ marginTop: 15 }}>
                        <Button
                          circular
                          color="yellow"
                          icon="book"
                          content="Lire Maintenant "
                          as={Link}
                          to={`/books/read/${book?.slug}`}
                        />
                        <Button circular icon="heart outline" />
                      </div>
                    </div>
                  ))}
              </Grid.Column>
            </Grid>
            <div
              className=""
              style={{
                marginTop: "2rem",
                display: "flex",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Button.Group>
                <Button
                  icon="play"
                  onClick={() => {
                    setIsPlaying(true);
                    if ("speechSynthesis" in window) {
                      if (window.speechSynthesis.speaking) {
                        window.speechSynthesis.resume();
                      } else {
                        const utterance = new SpeechSynthesisUtterance(
                          book?.description
                        );
                        utterance.lang = "fr-FR";
                        utterance.rate = 0.8;
                        window.speechSynthesis.speak(utterance);
                      }
                    } else {
                      alert(
                        "Votre navigateur ne supporte pas la Web Speech API."
                      );
                    }
                  }}
                />
                <Button
                  icon="pause"
                  onClick={() => {
                    if ("speechSynthesis" in window) {
                      window.speechSynthesis.pause();
                    } else {
                      alert(
                        "Votre navigateur ne supporte pas la Web Speech API."
                      );
                    }
                  }}
                />
                <Button
                  icon="stop"
                  onClick={() => {
                    if ("speechSynthesis" in window) {
                      setIsPlaying(false);
                      window.speechSynthesis.cancel();
                    } else {
                      alert(
                        "Votre navigateur ne supporte pas la Web Speech API."
                      );
                    }
                  }}
                />
              </Button.Group>
              {isPlaying && (
                <div className="boxContainer">
                  <div className="box box1"></div>
                  <div className="box box2"></div>
                  <div className="box box3"></div>
                  <div className="box box4"></div>
                  <div className="box box5"></div>
                </div>
              )}
            </div>
            <Divider />
            <div style={{ marginTop: "2rem" }}>
              {loading ? (
                <Loader active inline="centered">
                  Chargement en cours...
                </Loader>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: book?.description,
                  }}
                />
              )}
            </div>
            <div className="ui segment" style={{ marginTop: "2rem" }}>
              <Header as="h3">
                <Icon name="numbered list" />
                <Header.Content>
                  Table de matière
                  <Header.Subheader></Header.Subheader>
                </Header.Content>
              </Header>
              {loading ? (
                <Loader active inline="centered">
                  Chargement en cours...
                </Loader>
              ) : book?.Chapter?.length > 0 ? (
                <List divided relaxed>
                  {book?.Chapter?.map((chapter) => (
                    <List.Item key={chapter.id}>
                      <List.Icon
                        name="list alternate"
                        size="large"
                        verticalAlign="middle"
                      />
                      <List.Content>
                        <List.Header as="a">{chapter?.name}</List.Header>
                        <List.Description as="a">
                          {dayjs(
                            chapter?.updatedAt || chapter?.createdAt
                          ).fromNow()}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              ) : (
                <Message>
                  <Message.Content>
                    Aucun chapitre pour le livre sélectionné.
                  </Message.Content>
                </Message>
              )}
            </div>
          </Container>
        </Grid.Column>
        <Grid.Column width="6" as={Sticky}>
          <Container>
            <div>
              <Image
                src={require("../../assets/images/_279cb498-6db2-480f-8647-bb321cf61356.jpg")}
                style={{ cursor: "pointer", height: "350px", width: "100%" }}
                
              
              />
            </div>
            <BookSuggestions
              currentBookId={book?.id}
              genre={book?.bookCategory?.name}
            />
          </Container>
        </Grid.Column>
      </Grid>
    </Page>
  );
};

export default BookDetails;
