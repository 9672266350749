import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  Header,
  Image,
  Menu,
} from "semantic-ui-react";
import styled from "styled-components";
import HomePageFormContact from "../../components/HomePageFormContact";
import Page from "../../components/shared/Page";
import SimpleFooter from "../../components/shared/SimpleFooter";
import useResponsive from "../../hooks/useResponsive";

const RootStyle = styled.div`
  min-height: 100%;
  padding-top: 3rem;
`;

const HomePage = () => {
  const isMobile = useResponsive("(max-width: 767px)");

  return (
    <Page title="">
      <RootStyle>
        <Menu
          fixed="top"
          size="huge"
          inverted
          style={{
            backgroundColor: "#3f3d56",
            boxShadow: "rgba(67, 117, 151, 0.08) 0px 3px 7px 0px",
          }}
        >
          <Menu.Item icon="book" name="Kontbox" />

          <Menu.Menu position="right"></Menu.Menu>
        </Menu>

        <Grid
          stackable
          style={{
            flexDirection: isMobile ? "column-reverse" : "row",
            paddingLeft: isMobile ? "0rem" : "10rem",
            paddingRight: isMobile ? "0rem" : "10rem",
            minHeight: "100vh",
          }}
        >
          <Grid.Column
            width="8"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Container>
              <Header as="h1" color="yellow" style={{ fontWeight: "bold" }}>
                Bienvenue sur Kontbox
              </Header>
              <p style={{ fontWeight: "bold" }}>
                Renouez avec la lecture à travers des histoires passionnantes.
              </p>
              <p>
                Redécouvrez les bienfaits de la lecture à travers notre
                plateforme constituée d'une communauté d'auteurs et de lecteurs.
                Grand ou petit chacun trouve son compte, de la romance à
                l'horreur il y'en a pour tous les goûts.
              </p>
              <Button color="yellow" as={Link} to="login" circular>
                Je veux lire
              </Button>
            </Container>
          </Grid.Column>
          <Grid.Column width="8">
            <Image
              src={require("../../assets/images/undraw_Book_lover_re_rwjy.png")}
              alt="connexion"
              style={{ height: "100%" }}
            />
          </Grid.Column>
        </Grid>
        <Grid
          stackable
          reversed
          style={{
            paddingLeft: isMobile ? "0rem" : "10rem",
            paddingRight: isMobile ? "0rem" : "10rem",
            backgroundColor: "#f8f8f8",
          }}
        >
          <Grid.Column width="8">
            <Container>
              <Image
                src={require("../../assets/images/undraw_Bookshelves_re_lxoy-removebg-preview.png")}
                alt="connexion"
                style={{ height: "100%" }}
              />
            </Container>
          </Grid.Column>
          <Grid.Column
            width="8"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Container>
              <Header as="h2" color="yellow" style={{ fontWeight: "bold" }}>
                Des histoires pour les tous petits!
              </Header>
              <p style={{ fontWeight: "bold" }}>
                L'amour de la lecture dès la petite enfance.
              </p>
              <p>
                Sur Kontbox vous trouvez des contenus adaptés, pour vos enfants.
                Dans des chaines spécialisées, des histoires dans les catégories
                contes et légendes sont régulièrement publiées. Avec kontbox
                passez des moments merveilleux en compagnie de vos enfants.
              </p>
            </Container>
          </Grid.Column>
        </Grid>
        <Grid
          stackable
          style={{
            flexDirection: isMobile ? "column-reverse" : "row",
            paddingLeft: isMobile ? "0rem" : "10rem",
            paddingRight: isMobile ? "0rem" : "10rem",
          }}
        >
          <Grid.Column
            width="8"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Container>
              <Header as="h1" color="yellow" style={{ fontWeight: "bold" }}>
                Devenir auteur (e)
              </Header>
              <p style={{ fontWeight: "bold" }}>
                Faites exploser votre créativité ...!
              </p>
              <p>
                Kontbox c'est aussi, une plateforme developpée spécialement pour
                les auteur.es, dans le but de faciliter la rédaction et la
                publication du contenu. En trois clics vos livres sont partagés
                dans la plateforme au plaisir des lecteurs.
              </p>
              <Button color="yellow" circular as="a" target="_blank" href="https://writer.kontbox.net">
                Ouvrir ma chaine
              </Button>
            </Container>
          </Grid.Column>
          <Grid.Column width="8">
            <Image
              src={require("../../assets/images/undraw_Typewriter_re_u9i2.png")}
              alt="connexion"
              style={{ height: "100%" }}
            />
          </Grid.Column>
        </Grid>
        <Grid stackable style={{ backgroundColor: "#f8f8f8" }}>
          <Grid.Column width="16">
            <Container>
              <Header as="h2" color="yellow" style={{ fontWeight: "bold" }}>
                Témoignages
              </Header>
              <Grid columns={3} stackable divided>
                <Grid.Row>
                  <Grid.Column>
                    <p>
                      <i>"J'ai adoré cette plateforme ! Les histoires sont passionnantes et je me suis fait de nouveaux amis."</i>
                      <br />
                      <strong>- Utilisateur A</strong>
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <p>
                      <i>"Kontbox m'a aidé à améliorer ma créativité en tant qu'auteur. La communauté est incroyable et supportive."</i>
                      <br />
                      <strong>- Utilisateur B</strong>
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <p>
                      <i>"Les catégories de contes et légendes ont captivé mes enfants. Nous passons de bons moments ensemble."</i>
                      <br />
                      <strong>- Utilisateur C</strong>
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <p>
                      <i>"Je suis impressionné par la variété des genres disponibles. Il y a vraiment quelque chose pour tout le monde ici."</i>
                      <br />
                      <strong>- Utilisateur D</strong>
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <p>
                      <i>"En tant qu'écrivain, c'est fantastique d'avoir une plateforme qui rend si facile la publication et le partage de mon travail."</i>
                      <br />
                      <strong>- Utilisateur E</strong>
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <p>
                      <i>"Kontbox est ma nouvelle source préférée de lectures en ligne. Je suis accro aux histoires disponibles ici!"</i>
                      <br />
                      <strong>- Utilisateur F</strong>
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Grid.Column>
        </Grid>
        {/* FAQ section */}
        <Grid
          stackable
          style={{
            paddingTop: "2rem",
            paddingBottom: "2rem",
            paddingLeft: isMobile ? "0rem" : "10rem",
            paddingRight: isMobile ? "0rem" : "10rem",
          }}
        >
          <Grid.Column width="16">
            <Container textAlign="center">
              <Header as="h2" color="yellow" style={{ fontWeight: "bold" }}>
                Foire aux questions (FAQ)
              </Header>
              <details>
                <summary><strong>Question 1 :</strong> Comment créer un compte ?</summary>
                <p>Pour créer un compte, cliquez sur "Je veux lire", puis sélectionnez "Créer un compte". Remplissez les informations requises et cliquez sur "Inscription" pour terminer.</p>
              </details>
              <details>
                <summary><strong>Question 2 :</strong> Comment devenir auteur(e) ?</summary>
                <p>Pour devenir auteur(e), vous devez créer un compte utilisateur. Ensuite, cliquez sur "Ouvrir ma chaine" pour accéder à l'espace réservé aux auteurs.</p>
              </details>
              {/* Add more questions here */}
            </Container>
          </Grid.Column>
        </Grid>
        <Grid
          stackable
          reversed
          style={{
            paddingLeft: isMobile ? "0rem" : "10rem",
            paddingRight: isMobile ? "0rem" : "10rem",
            backgroundColor: "#f8f8f8",
            paddingTop: "2rem",
            paddingBottom: "2rem",
          }}
        >
          <Grid.Column width="8" style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <Container>
              <Image
                centered
                src={require("../../assets/images/undraw_Contact_us_re_4qqt-removebg-preview.png")}
                alt="connexion"
                style={{ height: "100%" }}
              />
            </Container>
          </Grid.Column>
          <Grid.Column
            width="8"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Container>
              <Header as="h2" color="yellow" style={{ fontWeight: "bold" }}>
                Laissez nous un message!
              </Header>
              <p style={{ fontWeight: "bold" }}>
                Notre équipe se chargera de vous répondre avec plaisir.
              </p>
              <HomePageFormContact />
            </Container>
          </Grid.Column>
        </Grid>
        <SimpleFooter withBackground />
      </RootStyle>
    </Page>
  );
};

export default HomePage;
