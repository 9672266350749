import React, { useState } from "react";
import Page from "../../../components/shared/Page";
import { Breadcrumb, Divider, Grid } from "semantic-ui-react";

import * as dashboardService from "../../../services/dashboardService";
import BestBooksChart from "../../../components/charts/BestBooksChart";

function DashboardPage() {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({});
  const [books, setBooks] = useState([]);
  const [bookcategories, setBookCategories] = useState([]);

  const fetchStats = async () => {
    try {
      const response = await dashboardService.getStats();
      if (response.status === 200) {
        setStats(response.data);
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchStats();

    return () => {};
  }, []);

  const fetchBestBooks = async () => {
    try {
      const response = await dashboardService.getBestBooks();
      if (response.status === 200 && response.data instanceof Array) {
        setBooks(response.data);
        console.log(response.data);
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchBestBooks();

    return () => {};
  }, []);
  /* 

  const fetchBestCategories = async () => {
    try {
      const response = await dashboardService.getBestCategories();
      if (response.status === 200 && response.data instanceof Array) {
        setBookCategories(response.data);
        console.log(response.data);
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchBestCategories();

    return () => {};
  }, []); */

  return (
    <Page title="Dashboard">
      <Grid.Row>
        <Grid.Column width={16}>
          <Breadcrumb divider>
            <Breadcrumb.Section link>Admin</Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section href="/admin" link>
              Dashboard
            </Breadcrumb.Section>
          </Breadcrumb>
          <Divider />
        </Grid.Column>
        <Grid.Column width={16}>
          <div
            className="ui grid stackable dashboard-stats"
            style={{ paddingTop: "30px" }}
          >
            <div className="four wide computer eight wide tablet sixteen wide mobile column">
              <div className="ui fluid card">
                <div className="content">
                  <div className="ui right floated header red">
                    <i className="icon users"></i>
                  </div>
                  <div className="header">
                    <div className="ui red header">
                      {stats?.totalUsers || 0}
                    </div>
                  </div>
                  <div className="meta">Utilisateurs</div>
                </div>
              </div>
            </div>
            <div className="four wide computer eight wide tablet sixteen wide mobile column">
              <div className="ui fluid card">
                <div className="content">
                  <div className="ui right floated header green">
                    <i className="icon book"></i>
                  </div>
                  <div className="header">
                    <div className="ui header green">
                      {stats?.totalBooks || 0}
                    </div>
                  </div>
                  <div className="meta"> Livres</div>
                </div>
              </div>
            </div>
            <div className="four wide computer eight wide tablet sixteen wide mobile column">
              <div className="ui fluid card">
                <div className="content">
                  <div className="ui right floated header teal">
                    <i className="icon thumbtack"></i>
                  </div>
                  <div className="header">
                    <div className="ui teal header">
                      {stats?.totalBookCategories || 0}
                    </div>
                  </div>
                  <div className="meta">Catégories</div>
                </div>
              </div>
            </div>
            <div className="four wide computer eight wide tablet sixteen wide mobile column">
              <div className="ui fluid card">
                <div className="content">
                  <div className="ui right floated header purple">
                    <i className="icon eye"></i>
                  </div>
                  <div className="header">
                    <div className="ui purple header">
                      {stats?.totalViews || 0}
                    </div>
                  </div>
                  <div className="meta">Lectures</div>
                </div>
              </div>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ marginTop: "30px" }}>
        <Grid.Column width={16}>
          <BestBooksChart data={books} />
        </Grid.Column>
      </Grid.Row>
    </Page>
  );
}

export default DashboardPage;
