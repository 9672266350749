import { api } from "./api";

export const getStats = async (params) => {
  return api.get("dashboard/stats", { params });
};

export const getBestBooks = async (params) => {
  return api.get("dashboard/best-books", { params });
};

export const getBestCategories = async (params) => {
  return api.get("dashboard/best-categories", { params });
};
