import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import App from "./App";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import reportWebVitals from "./reportWebVitals";
import { GlobalStyle } from "./components/styles/GlobalStyle";
import "simplebar/dist/simplebar.min.css";
import { SemanticToastContainer } from "react-semantic-toasts";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = {
  colors: {
    primary: "red",
  },
  breakpoints: {
    sm: "767px",
  },
};
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <App />
            <SemanticToastContainer />
            <ToastContainer />
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//ServiceWorker.register();
