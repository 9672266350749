import React from "react";
import { Button, Form, Icon, Segment } from "semantic-ui-react";

const HomePageFormContact = () => {
  return (
    <Form as={Segment}>
      <Form.Group widths="equal">
        <Form.Input label="Nom" fluid required />
        <Form.Input label="Prénom" fluid required />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input label="Téléphone" fluid required />
        <Form.Input label="E-mail" fluid required />
      </Form.Group>
      <Form.Input label="Sujet" required />
      <Form.TextArea label="Message" required />
      <Button color="yellow" fluid rounded><Icon name="send"/> Envoyer</Button>
    </Form>
  );
};

export default HomePageFormContact;
