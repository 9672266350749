import React from "react";
import { Button, Header, Icon, Image, Label, Modal } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { getBackendRoute } from "../../services/api";
import { getSmallTextDescription } from "../../helpers/commonsHelper";

const BookModalDetails = (props) => {
  const navigate = useNavigate();

  const handleGotoBookClick = (bookSlug) => (e) => {
    navigate(`/books/${bookSlug}`, { replace: true });
    props.setOpen(false);
  };
  return (
    <Modal
      onClose={() => props.setOpen(false)}
      onOpen={() => props.setOpen(true)}
      open={props.open}
      size="tiny"
      closeIcon
    >
      <Modal.Header></Modal.Header>
      <Modal.Content image>
        <Image
          size="medium"
          src={`${getBackendRoute()}images/books/${props.book?.image}`}
          wrapped
          rounded
          centered
        />
        <Modal.Description>
          <Header
            style={{
              marginBottom: 0,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            as="h4"
          >
            {props.book?.name}
          </Header>
          <div style={{ marginTop: 5, marginBottom: 5 }} as="h6">
            <Label as="a" size="mini">
              {props.book?.bookCategory?.name}
            </Label>
            <Label as="a" size="mini">
              {props.book?._count?.Chapter} chapitres
            </Label>
            <Label as="a" icon="eye" content={props.book?._count?.Views} size="mini" />
            <Label as="a" icon="star" content={props.book?._count?.Stars} size="mini" />
          </div>
          <p>{getSmallTextDescription(150, props.book?.description)}</p>
          <Button
            basic
            color="yellow"
            onClick={handleGotoBookClick(props.book?.slug)}
          >
            Lire plus
          </Button>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions></Modal.Actions>
    </Modal>
  );
};

export default BookModalDetails;
