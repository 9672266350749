import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import styled from "styled-components";

const RootStyle = styled.div`
  position: fixed;
  width: calc(100% - 260px);
  z-index: 1000;
  background-color: #fff;
  border-bottom: 2px solid #eee;
  top: 0;
  padding-top: 10px;
  @media (max-width: 767px) {
    width: 100%;
    position: sticky;
  }
`;

const WrapperStyle = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;
const LeftBoxStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 30%;
  max-width: 30%;
  border-right: 1px solid #eee;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    padding: 1rem;
  }
`;

const TopReaderBar = (props) => {
  return (
    <RootStyle>
      <WrapperStyle>
        <LeftBoxStyle>
          <Dropdown
            style={{
              display: "flex",
              justifyContent: "inherit",
              width: "100%",
            }}
            floating
            scrolling
            fluid
            icon="arrow circle down"
            trigger={
              <>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <h2 className="ui sub header">{props.book?.name}</h2>
                  <span>{`@${props.book?.channel?.channelName}`}</span>
                </div>
              </>
            }
          >
            <Dropdown.Menu>
              {props.book?.Chapter?.map((chapter, index) => (
                <Dropdown.Item
                  key={chapter.id}
                  text={chapter.name}
                  value={chapter.slug}
                  active={props.activeChapter === index}
                  onClick={() => {
                    props.setCurrentChapterIdx(index);
                    window.scrollTo(0, 0);
                  }}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </LeftBoxStyle>
      </WrapperStyle>
    </RootStyle>
  );
};

export default TopReaderBar;
