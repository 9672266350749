import { useEffect } from "react";
import {
  FormikProvide,
  Form as FormikForm,
  useFormik,
  FormikProvider,
} from "formik";
import { Button, Form, Icon } from "semantic-ui-react";
import * as Yup from "yup";
import * as userService from "../../services/userService";
import { toast } from "react-semantic-toasts";
import dayjs from "dayjs";
import { authState } from "../../atoms/authState";
import { useRecoilState } from "recoil";
import { saveUserData } from "../../services/authService";

const UserForm = (props) => {
  const [authStore, setAuthStore] = useRecoilState(authState);
  const schema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    birthday: Yup.date().required(),
    email: Yup.string().email().required(),
    phone: Yup.string().min(9).required(),
    country: Yup.string().nullable(),
    city: Yup.string().nullable(),
    gender: Yup.string().required(),
    description: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      birthday: "",
      email: "",
      phone: "",
      country: "",
      city: "",
      gender: "",
      description: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      userService
        .update(values)
        .then(
          (response) => {
            toast({
              time: 6000,
              description: "Profile mis à jour avec succès",
              type: "success",
            });
            props.setMe({ ...response.data });
            setAuthStore({ ...authStore, user: { ...response.data } });
            saveUserData({ ...authStore, user: { ...response.data } });
          },
          (reason) => {
            toast({
              time: 6000,
              description:
                reason?.response?.data ||
                "Une erreur s'est produite pendant l'opération",
              type: "error",
            });
          }
        )
        .catch((error) => {
          toast({
            time: 6000,
            description:
              error.message || "Une erreur s'est produite pendant l'opération",
            type: "error",
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    isSubmitting,
    setSubmitting,
  } = formik;

  useEffect(() => {
    setFieldValue("firstName", props.me?.firstName, false);
    setFieldValue("lastName", props.me?.lastName, false);
    setFieldValue("email", props.me?.email, false);
    setFieldValue("phone", props.me?.phone, false);
    setFieldValue(
      "birthday",
      dayjs(props.me?.birthday).format("YYYY-MM-DD"),
      false
    );
    setFieldValue("country", props.me?.country, false);
    setFieldValue("city", props.me?.city, false);
    setFieldValue("gender", props.me?.gender, false);
    setFieldValue("description", props.me?.description, false);

    return () => {};
  }, [props.me]);

  return (
    <FormikProvider value={formik}>
      <FormikForm className="ui form">
        <Form.Group widths="equal">
          <Form.Input
            label="Prénom"
            fluid
            required
            name="firstName"
            {...getFieldProps("firstName")}
            error={
              Boolean(touched.firstName && errors.firstName)
                ? { content: touched.firstName && errors.firstName }
                : false
            }
          />
          <Form.Input
            label="Nom"
            fluid
            required
            name="description"
            {...getFieldProps("lastName")}
            error={
              Boolean(touched.lastName && errors.lastName)
                ? { content: touched.lastName && errors.lastName }
                : false
            }
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            label="Téléphone"
            fluid
            required
            name="phone"
            {...getFieldProps("phone")}
            error={
              Boolean(touched.phone && errors.phone)
                ? { content: touched.phone && errors.phone }
                : false
            }
          />
          <Form.Input
            label="E-mail"
            fluid
            required
            name="email"
            {...getFieldProps("email")}
            error={
              Boolean(touched.email && errors.email)
                ? { content: touched.email && errors.email }
                : false
            }
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            label="Anniversaire"
            required
            type="date"
            fluid
            name="birthday"
            {...getFieldProps("birthday")}
            error={
              Boolean(touched.birthday && errors.birthday)
                ? { content: touched.birthday && errors.birthday }
                : false
            }
          />
          <Form.Select
            label="Je suis"
            required
            options={[
              {
                key: "Non defini",
                value: "NOT_DEFINE",
                text: "Non Spécifié",
              },
              {
                key: "Femme",
                value: "FEMALE",
                text: "Femme",
              },
              {
                key: "Homme",
                value: "MALE",
                text: "Homme",
              },
            ]}
            fluid
            name="gender"
            value={values.gender}
            onChange={(e, data) => setFieldValue("gender", data.value)}
            error={
              Boolean(touched.gender && errors.gender)
                ? { content: touched.gender && errors.gender }
                : false
            }
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            label="Pays"
            fluid
            name="country"
            {...getFieldProps("country")}
            error={
              Boolean(touched.country && errors.country)
                ? { content: touched.country && errors.country }
                : false
            }
          />
          <Form.Input
            label="Ville"
            fluid
            name="city"
            {...getFieldProps("city")}
            error={
              Boolean(touched.city && errors.city)
                ? { content: touched.city && errors.city }
                : false
            }
          />
        </Form.Group>
        <Form.TextArea
          label="A propos de moi"
          rows="6"
          name="description"
          {...getFieldProps("description")}
          error={
            Boolean(touched.description && errors.description)
              ? { content: touched.description && errors.description }
              : false
          }
        />
        <Form.Field style={{ marginTop: "10px" }}>
          <Button fluid color="yellow" type="submit" loading={isSubmitting}>
            <Icon name="save" />
            Enregistrer
          </Button>
        </Form.Field>
      </FormikForm>
    </FormikProvider>
  );
};

export default UserForm;
