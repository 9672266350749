import axios from "axios";

export const getBackendRoute = () => {
  return process.env.NODE_ENV === "production"
    ? "https://kontbox.crinteck.com/"
    : "http://localhost:5000/";
};
export const api = axios.create({
  baseURL: `${getBackendRoute()}api/`,
});

api.interceptors.request.use((config) => {
  try {
    let user = localStorage.getItem("kontbox@user");
    user = JSON.parse(user);
    config.headers.authorization = `Bearer ${user?.token}`;
    return config;
  } catch (error) {
    return config;
  }
});
