import { forwardRef } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

const Containerstyle = styled.div`
  height: 100%;
  margin-bottom: 2rem;
`;

const Page = forwardRef(({ children, title = "", ...others }, ref) => (
  <Containerstyle ref={ref} {...others}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </Containerstyle>
));

export default Page;
