import { Link, Outlet } from "react-router-dom";
import { Image, Menu } from "semantic-ui-react";
import styled from "styled-components";
import Page from "./Page";
import SimpleFooter from "./SimpleFooter";

const RootStyle = styled.div`
  min-height: 100%;
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
`;
const ContainerStyle = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 90%;
  flex-wrap: wrap;
`;
const LeftBoxStyle = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 50%;
  height: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
    height: auto;
  }
`;
const RightBoxStyle = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 50%;
  height: auto;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
    height: auto;
  }
`;

const AuthLayout = () => {
  return (
    <Page title="">
      <RootStyle>
        <Menu
          fixed="top"
          size="huge"
          
          style={{
            boxShadow: "rgba(67, 117, 151, 0.08) 0px 3px 7px 0px",
          }}
        >
          <Menu.Item icon="book" name="kontbox" as={Link} to="/" />

          <Menu.Menu position="right"></Menu.Menu>
        </Menu>

        <ContainerStyle>
          <LeftBoxStyle>
            <Image
              src={require("../../assets/images/undraw_Reading_time_re_phf7.png")}
              alt="connexion"
              style={{ height: "100%" }}
            />
          </LeftBoxStyle>
          <RightBoxStyle>
            <Outlet />
          </RightBoxStyle>
        </ContainerStyle>
        <SimpleFooter />
      </RootStyle>
    </Page>
  );
};

export default AuthLayout;
