import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import SubscriptionsList from "./SubscriptionsList";


const SubscriptionsIndex = () => {
  return (
      <Routes>
          <Route path="/" element={<SubscriptionsList/>}/>
      </Routes>
  );
};

export default SubscriptionsIndex;
