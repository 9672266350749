import { api } from "./api";

export const getBooks = async (params) => {
  return api.get("books", { params });
};

export const getBookBySlug = async (slug = "") => {
  return api.get(`books/find/by-slug?slug=${slug}`);
};

export const getBookCategories = async () => {
  return api.get(`book-categories`);
};

export const likeBook = async (bookId) => {
  return api.post(`books/${bookId}/like`);
};

export const generateBook = async () => {
  return api.get(`ghost-writer/create-history`);
};
