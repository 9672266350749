import React from "react";
import { Grid, Header, Image, Label, Segment } from "semantic-ui-react";
import { getSmallTextDescription } from "../../helpers/commonsHelper";
import { getBackendRoute } from "../../services/api";

const BookInfosBox = (props) => {
  return (
    <Grid.Column width="8" style={{ minHeight: "265px" }}>
      <Segment style={{ minHeight: "100%" }}>
        <Grid style={{ height: "100%" }}>
          <Grid.Column width="6">
            <Image
              src={`${getBackendRoute()}images/books/${props.book?.image}`}
              rounded
              style={{ cursor: "pointer", height: "250px" }}
              onClick={props.openBookModalDetails(props.book?.id)}
            />
          </Grid.Column>
          <Grid.Column
            width="10"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "space-between",
            }}
          >
            <Header
              style={{
                marginBottom: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
              as="h4"
              onClick={props.openBookModalDetails(props.book?.id)}
            >
              {props.book?.name}
            </Header>
            <div style={{ marginTop: 5, marginBottom: 5 }} as="h6">
              <Label as="a" size="mini">
                {props.book?.bookCategory?.name}
              </Label>
              <Label as="a" size="mini">
                {props.book?._count?.Chapter} chapitres
              </Label>
              <Label as="a" icon="eye" content={props.book?._count?.Views} size="mini" />
              <Label as="a" icon="star" content={props.book?._count?.Stars} size="mini" />

            </div>
            <p>{getSmallTextDescription(150, props.book?.description)}</p>
          </Grid.Column>
        </Grid>
      </Segment>
    </Grid.Column>
  );
};

export default BookInfosBox;
