import React, { useEffect, useState } from "react";

import * as bookService from "../../../services/bookService";
import Page from "../../../components/shared/Page";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Dropdown,
  Grid,
  Icon,
  Input,
  Loader,
  Pagination,
} from "semantic-ui-react";
import useResponsive from "../../../hooks/useResponsive";
import dayjs from "dayjs";
import { toast } from "react-semantic-toasts";

function BookList() {
  const [books, setBooks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalBooks, setTotalBooks] = useState(0);
  const [loading, setLoading] = useState(false);
  const [bookcategories, setBookCategories] = useState([]);
  const [selectedBookId, setSelectedBookId] = useState();
  const [creatingBook, setCreatingBook] = useState(false);

  const [selectedBookCategoryName, setSelectedBookCategoryName] = useState();

  const isMobile = useResponsive("(max-width:767px)");

  const fetchBooks = async () => {
    try {
      setLoading(true);
      const response = await bookService.getBooks({
        page: currentPage,
        search: searchTerm,
        genre: selectedBookCategoryName,
      });
      if (response.status === 200) {
        setBooks(response.data.data);
        setTotalPages(response.data.totalPages);
        setTotalBooks(response.data.totalBooks);
        setBooks(response.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchBookCategories = async () => {
    const response = await bookService.getBookCategories();
    if (response.status === 200) {
      setBookCategories(response.data);
    }
  };

  useEffect(() => {
    fetchBookCategories();
    return () => {};
  }, []);

  useEffect(() => {
    fetchBooks();
    return () => {};
  }, [currentPage, searchTerm, selectedBookCategoryName]);

  const createBook = async () => {
    try {
      setCreatingBook(true);
      const response = await bookService.generateBook();
      if (response.status === 201) {
        fetchBooks();
        toast({
          type: "success",
          icon: "check",
          title: "Succès",
          description: "Le livre a été généré avec succès",
          animation: "bounce",
          time: 5000,
        });
      }

      setCreatingBook(false);
    } catch (error) {
      toast({
        type: "error",
        icon: "times",
        title: "Erreur",
        description: "Une erreur est survenue lors de la génération du livre",
        animation: "bounce",
        time: 5000,
      });
      setCreatingBook(false);
    }
  };

  return (
    <Page title={`Liste des livres`}>
      <Grid>
        <Grid.Row>
          <Breadcrumb>
            <BreadcrumbSection link>Admin</BreadcrumbSection>
            <BreadcrumbDivider />
            <BreadcrumbSection link>Dashboard</BreadcrumbSection>
            <BreadcrumbDivider />
            <BreadcrumbSection active>Liste des livres</BreadcrumbSection>
          </Breadcrumb>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="8">
            <Input icon fluid placeholder="Rechercher ...">
              <input
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ borderRadius: "2rem" }}
              />
              <Icon name="search" />
            </Input>
          </Grid.Column>
          <Grid.Column width="8" textAlign="right">
            <Dropdown
              text={
                selectedBookCategoryName
                  ? selectedBookCategoryName
                  : "Tous les genres"
              }
              icon="filter"
              floating
              labeled
              button
              className="icon"
              style={{ minWidth: isMobile ? "100%" : "60%" }}
              scrolling
            >
              <Dropdown.Menu>
                <Dropdown.Header icon="tags" content="Filter by tag" />
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={() => setSelectedBookCategoryName(null)}
                >
                  Tous les genres
                </Dropdown.Item>
                {bookcategories?.map((cat) => (
                  <Dropdown.Item
                    onClick={() => setSelectedBookCategoryName(cat.name)}
                  >
                    {cat.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Button
              className="teal"
              onClick={() => {
                createBook();
              }}
              style={{ marginLeft: "10px" }}
              loading={creatingBook}
            >
              <Icon name="magic" />
              Générer
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="16">
            <div className="table-responsive">
              {loading ? (
                <Loader
                  active
                  inline="centered"
                  style={{
                    marginTop: "100px",
                    marginBottom: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  Chargement en cours...
                </Loader>
              ) : (
                <>
                  <table className="ui celled table">
                    <thead>
                      <tr>
                        <th>Titre</th>
                        <th>Genre</th>
                        <th>Statut</th>
                        <th>Chaine</th>
                        <th>Chapitres</th>
                        <th>Vues</th>
                        <th>Date</th>
                        <th className="collapsing">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {books?.map((book) => (
                        <tr key={book.id}>
                          <td>{book.name}</td>
                          <td>{book.bookCategory?.name}</td>
                          <td>{book.status}</td>
                          <td>{book.channel?.channelName}</td>
                          <td>{book._count?.Chapter}</td>
                          <td>{book._count?.Views}</td>
                          <td>
                            {dayjs(book.createdAt).format("DD/MM/YYYY HH:mm")}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <Dropdown
                              icon="ellipsis vertical"
                              floating
                              className="icon"
                              style={{ minWidth: "auto" }}
                              pointing="right"
                            >
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  icon="eye"
                                  text="Voir"
                                  onClick={() => {}}
                                />
                                <Dropdown.Item
                                  icon="edit"
                                  text="Modifier"
                                  onClick={() => {}}
                                />
                                <Dropdown.Item
                                  icon="trash"
                                  text="Supprimer"
                                  onClick={() => {}}
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    style={{ float: "right", marginTop: "20px" }}
                    defaultActivePage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(e, { activePage }) =>
                      setCurrentPage(activePage)
                    }
                  />
                </>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Page>
  );
}

export default BookList;
