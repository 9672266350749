import { api } from "./api";

export const me = async () => {
  let userData = localStorage.getItem("kontbox@user");
  try {
    userData = JSON.parse(userData);
  } catch (error) {
    userData = null;
  }
  return api.get(`users/${userData?.user?.id}`);
};
export const update = async (data) => {
  return api.put(`users`, data);
};
export const changeProfileImage = async (file) => {
  return api.put("users/change-profile-image", file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
