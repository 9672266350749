import { useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Accordion, Icon, Menu } from "semantic-ui-react";
import { authState } from "../../atoms/authState";

const SidebarMenu = (props) => {
  const [authStore, setAuthStore] = useRecoilState(authState);

  const [activeName, setActiveName] = useState("books");

  const handleAccordeonClick = (e, titleProps) => {
    const { name } = titleProps;
    const newName = activeName === name ? name : name;
    setActiveName(newName);

    const parent = e.target.parentElement;
    if (parent.classList.contains("content")) {
      const titleAccoord = parent.previousElementSibling;
      titleAccoord.classList.toggle("active");
      parent.classList.add("active");
    }
  };
  return (
    <Accordion
      as={Menu}
      vertical
      fluid
      inverted
      style={{ borderRadius: 0, border: "none", backgroundColor: "#3f3d56" }}
    >
      <Menu.Item
        as={Link}
        to="/books"
        name="books"
        active={activeName === "books"}
        onClick={handleAccordeonClick}
      >
        <Icon name="book" />
        Livres
      </Menu.Item>
      <Menu.Item
        as={Link}
        to="/profile"
        name="profile"
        active={activeName === "profile"}
        onClick={handleAccordeonClick}
      >
        <Icon name="user" />
        Mon profile
      </Menu.Item>
      <Menu.Item
        as={Link}
        to="change-password"
        name="change-password"
        active={activeName === "change-password"}
        onClick={handleAccordeonClick}
      >
        <Icon name="key" />
        Changer le mot de passe
      </Menu.Item>
      {authStore?.user?.role?.includes("ADMIN") && (
        <Menu.Item>
          <Accordion.Title
            active={activeName === "admin"}
            name="admin"
            onClick={handleAccordeonClick}
          >
            Administration
          </Accordion.Title>
          <Accordion.Content active={activeName === "admin"}>
            <Menu.Item
              as={Link}
              to="/admin"
              name="admin-dashboard"
              active={activeName === "admin-dashboard"}
              onClick={handleAccordeonClick}
            >
              <Icon name="dashboard" />
              Dashboard
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/admin/books"
              name="admin-books"
              active={activeName === "admin-books"}
              onClick={handleAccordeonClick}
            >
              <Icon name="book" />
              Livres
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/admin/categories"
              name="admin-bookcategories"
              active={activeName === "admin-bookcategories"}
              onClick={handleAccordeonClick}
            >
              <Icon name="tags" />
              Catégories
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/admin/users"
              name="users"
              active={activeName === "users"}
              onClick={handleAccordeonClick}
            >
              <Icon name="users" />
              Utilisateurs
            </Menu.Item>
          </Accordion.Content>
        </Menu.Item>
      )}
      <Menu.Item as={Link} to="!#" onClick={props.handleLogOut}>
        <Icon name="log out" />
        Déconnexion
      </Menu.Item>
    </Accordion>
  );
};

export default SidebarMenu;
