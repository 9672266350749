import { useState } from "react";
import {
  Button,
  Container,
  Form,
  GridColumn,
  GridRow,
  Header,
  Segment,
} from "semantic-ui-react";
import * as Yup from "yup";
import { FormikProvider, Form as FormikForm, useFormik } from "formik";
import Page from "../../components/shared/Page";
import { changePassword } from "../../services/authService";
import { toast } from "react-semantic-toasts";

const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const schema = Yup.object().shape({
    oldPassword: Yup.string().required().min(8).max(60),
    newPassword: Yup.string().required().min(8).max(60),
    passwordConfirm: Yup.string()
      .required()
      .oneOf([Yup.ref("newPassword"), null]),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      passwordConfirm: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const { passwordConfirm, ...rest } = values;
      changePassword(rest)
        .then(
          (response) => {
            toast({
              description: "Votre mot de passe a été changé avec succès!",
              type: "success",
              time: 6000,
            });
          },
          (reason) => {
            toast({
              description:
                reason.response?.data ||
                "Une erreur s'est produite pendant l'opération",
              type: "error",
              time: 6000,
            });
          }
        )
        .catch((reason) => {
          toast({
            description: "Une erreur s'est produite pendant l'opération",
            type: "error",
            time: 6000,
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
    getFieldProps,
  } = formik;
  return (
    <Page title="Changement du mot de passe">
      <GridRow>
        <GridColumn>
          <Container as={Segment}>
            <FormikProvider value={formik}>
              <FormikForm className="ui form">
                <Header as="h2" dividing>
                  Mise à jour du mot de passe
                </Header>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    label="Ancien mot de passe"
                    name="oldPassword"
                    action={{
                      icon: showPassword ? "eye" : "eye slash",
                      onClick: (e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      },
                    }}
                    {...getFieldProps("oldPassword")}
                    error={
                      Boolean(touched.oldPassword && errors.oldPassword)
                        ? { content: touched.oldPassword && errors.oldPassword }
                        : false
                    }
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    label="Nouveau mot de passe"
                    action={{
                      icon: showPassword ? "eye" : "eye slash",
                      onClick: (e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      },
                    }}
                    name="newPassword"
                    {...getFieldProps("newPassword")}
                    error={
                      Boolean(touched.newPassword && errors.newPassword)
                        ? { content: touched.newPassword && errors.newPassword }
                        : false
                    }
                  />
                  <Form.Input
                    required
                    label="Confirmez le nouveau mot de passe"
                    name="passwordConfirm"
                    action={{
                      icon: showPassword ? "eye" : "eye slash",
                      onClick: (e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      },
                    }}
                    type={showPassword ? "text" : "password"}
                    {...getFieldProps("passwordConfirm")}
                    error={
                      Boolean(touched.passwordConfirm && errors.passwordConfirm)
                        ? {
                            content:
                              touched.passwordConfirm && errors.passwordConfirm,
                          }
                        : false
                    }
                  />
                </Form.Group>
                <Button
                  icon="save"
                  content="Enregistrer"
                  primary
                  type="submit"
                  loading={isSubmitting}
                />
              </FormikForm>
            </FormikProvider>
          </Container>
        </GridColumn>
      </GridRow>
    </Page>
  );
};

export default ChangePassword;
