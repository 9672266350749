import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { Button, Header, Form } from "semantic-ui-react";
import { FormikProvider, useFormik, Form as FormikForm } from "formik";
import { toast } from "react-semantic-toasts";
import * as Yup from "yup";
import * as authService from "../../services/authService";
import { useSetRecoilState } from "recoil";
import { authState } from "../../atoms/authState";

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const setAuthStore = useSetRecoilState(authState);

  const schema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      authService
        .login(values)
        .then(
          (response) => {
            authService.saveUserData(response.data);
            setAuthStore(response.data);
          },
          (reason) => {
            toast({
              title: "Connexion",
              type: "error",
              description: reason.response?.data,
              time: 6000,
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });
  const {
    values,
    touched,
    errors,
    setSubmitting,
    isSubmitting,
    getFieldProps,
  } = formik;
  return (
    <>
      <Helmet>
        <title>Se connecter</title>
      </Helmet>
      <FormikProvider value={formik}>
        <FormikForm className="login-form ui form segment">
          <Header textAlign="center" as="h3" color="grey">
            Connexion
          </Header>
          <Form.Input
            label="Email"
            required
            name="email"
            {...getFieldProps("email")}
            error={
              Boolean(touched.email && errors.email)
                ? { content: touched.email && errors.email }
                : false
            }
          />
          <Form.Input
            label="Mot de passe"
            required
            name="password"
            {...getFieldProps("password")}
            error={
              Boolean(touched.password && errors.password)
                ? { content: touched.password && errors.password }
                : false
            }
            action={{
              icon: showPassword ? "eye" : "eye slash",
              onClick: (e) => {
                e.preventDefault();
                setShowPassword(!showPassword);
              },
            }}
            type={showPassword ? "text" : "password"}
          />
          <div
            style={{
              textAlign: "right",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Link to="/auth/forgot-password">Mot de passe oublié ?</Link>
          </div>
          <Form.Field>
            <Button
              icon="sign in"
              content="Se connecter"
              fluid
              color="yellow"
              type="submit"
              className="submit"
              loading={isSubmitting}
            />
          </Form.Field>
          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Link to="/auth/register">
              <Header className="ui text yellow">créer un compte</Header>
            </Link>
          </div>
        </FormikForm>
      </FormikProvider>
    </>
  );
};

export default LoginPage;
