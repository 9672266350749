import React, { useEffect, useState } from "react";
import Page from "../../../components/shared/Page";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Dropdown,
  Grid,
  Icon,
  Input,
  Loader,
  Pagination,
} from "semantic-ui-react";
import useResponsive from "../../../hooks/useResponsive";

import * as categoriesService from "../../../services/categoriesService";

function BookCategoriesList() {
  const [bookcategories, setBookCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBookId, setSelectedBookId] = useState();
  const [selectedBookCategoryName, setSelectedBookCategoryName] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [totalCategories, setTotalCategories] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const isMobile = useResponsive("(max-width:767px)");

  const fetchBookCategories = async () => {
    try {
      setLoading(true);
      const response = await categoriesService.getCategories({
        page: currentPage,
        search: searchTerm,
      });
      if (response.status === 200) {
        setBookCategories(response.data.data);
        setTotalPages(response.data.totalPages);
        setTotalCategories(response.data.totalCategories);
        setBookCategories(response.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBookCategories();
    return () => {};
  }, [currentPage, searchTerm]);

  return (
    <Page title="Book Categories Liste">
      <Grid.Row>
        <Grid.Column width={16}>
          <Breadcrumb>
            <BreadcrumbSection href="/admin" link>
              Admin
            </BreadcrumbSection>
            <BreadcrumbDivider />
            <BreadcrumbSection active>Book Categories</BreadcrumbSection>
          </Breadcrumb>
        </Grid.Column>
      </Grid.Row>
      <Grid stackable style={{ marginTop: "20px" }}>
        <Grid.Column width="8">
          <Input icon fluid placeholder="Rechercher ...">
            <input
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ borderRadius: "2rem" }}
            />
            <Icon name="search" />
          </Input>
        </Grid.Column>
        <Grid.Column width="8" textAlign="right">
          <Button
            icon
            labelPosition="left"
            color="green"
            href="/admin/categories/add"
          >
            <Icon name="plus" />
            Ajouter
          </Button>
        </Grid.Column>
        <Grid.Column width="16">
          {loading ? (
            <Loader
              active
              inline="centered"
              style={{
                marginTop: "100px",
                marginBottom: "20px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              Chargement en cours...
            </Loader>
          ) : (
            <>
              <table className="ui celled table">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Slug</th>
                    <th>Active</th>
                    <th className="collapsing">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {bookcategories?.map((bookcategory) => (
                    <tr key={bookcategory.id}>
                      <td>{bookcategory.name}</td>
                      <td>{bookcategory.slug}</td>
                      <td>{bookcategory.active ? "Oui" : "Non"}</td>
                      <td>
                        <Dropdown
                          icon="ellipsis vertical"
                          floating
                          className="icon"
                          style={{ minWidth: isMobile ? "100%" : "60%" }}
                          pointing="top right"
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              href={`/admin/categories/edit/${bookcategory.id}`}
                            >
                              <Icon name="edit" />
                              Modifier
                            </Dropdown.Item>
                            <Dropdown.Item
                              href={`/admin/categories/delete/${bookcategory.id}`}
                            >
                              <Icon name="trash" />
                              Supprimer
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                style={{ float: "right", marginTop: "20px" }}
                defaultActivePage={currentPage}
                totalPages={totalPages}
                onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
              />
            </>
          )}
        </Grid.Column>
      </Grid>
    </Page>
  );
}

export default BookCategoriesList;
