import React from "react";
import {
  Dropdown,
  Form,
  GridColumn,
  GridRow,
  Header,
  Input,
  Segment,
  Table,
} from "semantic-ui-react";
import Page from "../../components/shared/Page";
import useResponsive from "../../hooks/useResponsive";

const NotificationsList = () => {
  const isMobile = useResponsive("(max-width: 767px)");
  return (
    <Page title="Gestion des Notifications">
      <GridRow>
        <GridColumn
          style={{
            justifyContent: "space-between",
            display: isMobile ? "block" : "flex",
            alignItems: "center",
          }}
        >
          <Header style={{ marginBottom: 0 }}>Liste des Notifications</Header>
          <div>
            <Input
              icon="search"
              style={{
                borderRadius: "10px",
                marginRight: "10px",
                width: isMobile ? "100%" : "auto",
                marginTop: isMobile ? "10px" : 0,
              }}
              search
            />
            <Dropdown
              text="Filter"
              icon="filter"
              floating
              labeled
              button
              className="icon"
              style={{
                marginTop: "10px",
                width: isMobile ? "100%" : "auto",
              }}
            >
              <Dropdown.Menu>
                <Dropdown.Header icon="tags" content="Filter by tag" />
                <Dropdown.Item>Important</Dropdown.Item>
                <Dropdown.Item>Announcement</Dropdown.Item>
                <Dropdown.Item>Discussion</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </GridColumn>
      </GridRow>
      <Table singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Registration Date</Table.HeaderCell>
            <Table.HeaderCell>E-mail address</Table.HeaderCell>
            <Table.HeaderCell>Premium Plan</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>John Lilki</Table.Cell>
            <Table.Cell>September 14, 2013</Table.Cell>
            <Table.Cell>jhlilk22@yahoo.com</Table.Cell>
            <Table.Cell>No</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Jamie Harington</Table.Cell>
            <Table.Cell>January 11, 2014</Table.Cell>
            <Table.Cell>jamieharingonton@yahoo.com</Table.Cell>
            <Table.Cell>Yes</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Jill Lewis</Table.Cell>
            <Table.Cell>May 11, 2014</Table.Cell>
            <Table.Cell>jilsewris22@yahoo.com</Table.Cell>
            <Table.Cell>Yes</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Page>
  );
};

export default NotificationsList;
