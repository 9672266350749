import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Header } from "semantic-ui-react";
import { FormikProvider, useFormik, Form as FormikForm } from "formik";
import { toast } from "react-semantic-toasts";
import * as Yup from "yup";
import * as authService from "../../services/authService";

const RegisterPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const schema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      authService
        .register(values)
        .then(
          (response) => {
            toast({
              time: 6000,
              type: "success",
              description: "Votre compte a été créé avec succès.",
            });
            navigate("/auth/login");
          },
          (reason) => {
            toast({
              title: "Connexion",
              type: "error",
              description:
                reason.response?.data ||
                "Une erreur est survenu pendant la création du compte",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });
  const {
    values,
    touched,
    errors,
    setSubmitting,
    isSubmitting,
    getFieldProps,
  } = formik;
  return (
    <>
      <Helmet>
        <title>Création de compte</title>
      </Helmet>
      <FormikProvider value={formik}>
        <FormikForm className="login-form ui form segment">
          <Header textAlign="center" as="h3" color="grey">
            Création du compte
          </Header>
          <Form.Group className="two equal wide">
            <Form.Input
              label="Nom"
              required
              {...getFieldProps("lastName")}
              error={
                Boolean(touched.lastName && errors.lastName)
                  ? { content: touched.lastName && errors.lastName }
                  : false
              }
            />
            <Form.Input
              label="Prénom"
              required
              {...getFieldProps("firstName")}
              error={
                Boolean(touched.firstName && errors.firstName)
                  ? { content: touched.firstName && errors.firstName }
                  : false
              }
            />
          </Form.Group>
          <Form.Input
            label="Email"
            required
            {...getFieldProps("email")}
            error={
              Boolean(touched.email && errors.email)
                ? { content: touched.email && errors.email }
                : false
            }
          />
          <Form.Input
            label="Mot de passe"
            required
            action={{
              icon: showPassword ? "eye" : "eye slash",
              onClick: (e) => {
                e.preventDefault();
                setShowPassword(!showPassword);
              },
            }}
            type={showPassword ? "text" : "password"}
            {...getFieldProps("password")}
            error={
              Boolean(touched.password && errors.password)
                ? { content: touched.password && errors.password }
                : false
            }
          />
          <Form.Field>
            <Button
              icon="sign in"
              content="Créer mon compte"
              fluid
              color="yellow"
              type="submit"
              className="submit"
              loading={isSubmitting}
            />
          </Form.Field>
          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Header as={Link} className="ui text yellow " to="/auth/login">
              Se connecter
            </Header>
          </div>
        </FormikForm>
      </FormikProvider>
    </>
  );
};

export default RegisterPage;
