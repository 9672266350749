import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import MessagesList from "./MessagesList";


const MessagesIndex = () => {
  return (
      <Routes>
          <Route path="/" element={<MessagesList/>}/>
      </Routes>
  );
};

export default MessagesIndex;
