import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { toast } from "react-semantic-toasts";
import { useRecoilState } from "recoil";
import {
  Grid,
  GridColumn,
  Header,
  Label,
  List,
  Segment,
} from "semantic-ui-react";
import { authState } from "../../atoms/authState";
import Page from "../../components/shared/Page";
import UserForm from "../../components/shared/UserForm";
import * as userService from "../../services/userService";

const ProfilePage = () => {
  const [authStore, setAuthstore] = useRecoilState(authState);
  const [me, setMe] = useState();

  const fetchMe = async () => {
    try {
      const response = await userService.me();
      if (response.status === 200 && response.data instanceof Object) {
        setMe(response.data);
      }
    } catch (error) {
      toast({
        time: 6000,
        description: error.message,
      });
    }
  };

  useEffect(() => {
    fetchMe();
    return () => {};
  }, []);

  return (
    <Page title="Writer | Mon Profile">
      <Grid>
        <GridColumn>
          <Header
            as="h2"
            icon="yellow vcard"
            content="Mon profile"
            color="grey"
            dividing
          />
        </GridColumn>
      </Grid>
      <Grid stackable colums="2">
        <Grid.Column width="6">
          <Segment>
            <List>
              <List.Item>
                <List.Icon name="tags" />
                <List.Content>
                  <List.Header as="a">Type de Compte</List.Header>
                  <List.Description style={{ marginTop: "10px" }}>
                    {me?.role?.map((role) => {
                      return (
                        <Label as="a" tag key={role}>
                          {role}
                        </Label>
                      );
                    })}
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="time" />
                <List.Content>
                  <List.Header as="a">Membre depuis</List.Header>
                  <List.Description>
                    {dayjs(me?.registerDate).format("DD-MM-YYYY")}
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Segment>
        </Grid.Column>
        <Grid.Column width="10">
          <Segment>
            <UserForm me={me} setMe={setMe} />
          </Segment>
        </Grid.Column>
      </Grid>
    </Page>
  );
};

export default ProfilePage;
