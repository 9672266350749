import { Link } from "react-router-dom";
import styled from "styled-components";

const FooterStyle = styled.footer`
  justify-content: baseline;
  align-items: center;
  flex-direction: column;
  vertical-align: middle;
  display: flex;
  height: 10%;
 

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
    height: 10%;
    text-align: center;
  }
`;
const SimpleFooter = (props) => {
  return (
   <div style={{  background: props.withBackground ? "#3f3d56" : "#FFFF", }}>
      <FooterStyle>
      <div style={{ marginTop: "10px" }}> Copyright &copy; {` ${new Date().getFullYear()}`} KontBox</div>
      <div>
        <Link to="/">Conditions de service</Link> |{" "}
        <Link to="/">Déclaration de confidentialité</Link>{" "}
      </div>
    </FooterStyle>
   </div>
  );
};

export default SimpleFooter;
