import { useRef, useState } from "react";
import { toast } from "react-semantic-toasts";
import { useRecoilState } from "recoil";
import { Image, Header, Button } from "semantic-ui-react";
import defaultUserImage from "../../assets/images/default_user.jpg";
import { authState } from "../../atoms/authState";
import { saveUserData } from "../../services/authService";
import { changeProfileImage } from "../../services/userService";
import { getBackendRoute } from "../../services/api";

const SidebarProfileBox = () => {
  const [authStore, setAuthStore] = useRecoilState(authState);
  const [loading, setLoading] = useState(false);
  const imageRef = useRef();
  const fileInputRef = useRef();

  const handleChangeProfileImageClick = async () => {
    fileInputRef.current?.click();
  };

  const fileInputChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    if (file.size > 2097152) {
      toast({
        time: 6000,
        description: "La Taille du fichier doit être inférieure à 2M",
        type: "error",
      });
      return;
    }
    imageRef.current.src = window.URL.createObjectURL(file);
    setLoading(true);
    changeProfileImage({ image: file })
      .then(
        (response) => {
          toast({
            time: 6000,
            type: "success",
            description: "Photo mise à jour avec succès.",
          });
          setAuthStore({ ...authStore, user: { ...response.data } });
          saveUserData({ ...authStore, user: { ...response.data } });
        },
        (reason) => {
          toast({
            time: 6000,
            type: "error",
            description:
              reason?.response?.data ||
              "Une erreur est arrivée pendant l'opération",
          });
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div style={{ padding: "1rem" }}>
      <img
        className="ui centered circular small image"
        src={
          authStore?.user?.profileImage
            ? `${getBackendRoute()}images/profiles/${authStore?.user?.profileImage}`
            : defaultUserImage
        }
        size="small"
        circular
        alt=""
        centered
        style={{
          border: "2px solid  #f9a826",
          height: "150px",
          width: "150px",
        }}
        ref={imageRef}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = defaultUserImage;
        }}
      />
      <Button
        icon="camera"
        circular
        style={{ position: "absolute", right: 40, top: 40 }}
        onClick={handleChangeProfileImageClick}
        loading={loading}
      />
      <input
        type="file"
        hidden
        ref={fileInputRef}
        accept="image/*"
        onChange={fileInputChange}
      />
      <Header
        as="h3"
        style={{
          marginBottom: 0,
          color: "white",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        textAlign="center"
      >
        {`${authStore?.user?.firstName} ${authStore?.user?.lastName}`}
      </Header>
      <Header
        as="h5"
        style={{
          marginBottom: 0,
          marginTop: 3,
          color: "white",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        textAlign="center"
      >
        {`${authStore?.user?.email}`}
      </Header>
    </div>
  );
};

export default SidebarProfileBox;
