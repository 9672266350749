import React, { useEffect, useState } from "react";
import { Grid, Header, Segment, Image, Icon } from "semantic-ui-react";
import { getSmallTextDescription } from "../../helpers/commonsHelper";
import { getBooks } from "../../services/bookService";
import BookModalDetails from "./BookModalDetails";
import { getBackendRoute } from "../../services/api";

function BookSuggestions({ genre, currentBookId = null }) {
  const [books, setBooks] = useState([]);
  const [selectedBookId, setSelectedBookId] = useState();
  const [open, setOpen] = React.useState(false);

  const fetchBooks = async () => {
    const response = await getBooks({
      genre: genre,
    });
    if (response.status === 200) {
      setBooks(response.data.data);
    }
  };

  const openBookModalDetails = (bookId) => (e) => {
    if (!bookId) return false;
    setSelectedBookId(bookId);
    setOpen(true);
  };

  useEffect(() => {
    if (genre?.length > 0) {
      fetchBooks();
    }
    return () => { };
  }, [genre]);
  return (
    <Segment>
      <Header as="h4">Vous pourriez aussi aimer</Header>

      {books?.map((b) => (
        currentBookId !== b.id && (<Grid>
          <Grid.Column width="6">
            <Image
              src={`${getBackendRoute()}images/books/${b.image}`}
              rounded
              style={{ cursor: "pointer", height: "175px" }}
              onClick={openBookModalDetails(b?.id)}
            />
          </Grid.Column>
          <Grid.Column width="10" style={{}}>
            <Header
              style={{
                marginBottom: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
              as="h4"
              onClick={openBookModalDetails(b?.id)}
            >
              {b?.name}
            </Header>
            <Header color="grey" style={{ marginTop: 0 }} as="h6">
              <Icon style={{ marginRight: 0 }} name="list alternate outline" />
              {` ${b?._count.Chapter} Chapitres`}
            </Header>
            <p style={{ fontSize: "12px" }}>
              {getSmallTextDescription(150, b?.description)}
            </p>
          </Grid.Column>
        </Grid>)))}
      <BookModalDetails
        book={books?.find((book) => book.id === selectedBookId)}
        open={open}
        setOpen={setOpen}
      />
    </Segment>
  );
}

export default BookSuggestions;
