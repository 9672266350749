import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Grid,
  Icon,
  Input,
  Loader,
  Pagination,
} from "semantic-ui-react";
import BookInfosBox from "../../components/shared/BookInfosBox";
import BookModalDetails from "../../components/shared/BookModalDetails";
import Page from "../../components/shared/Page";
import useResponsive from "../../hooks/useResponsive";
import * as bookService from "../../services/bookService";

const BookList = () => {
  const [open, setOpen] = React.useState(false);
  const [books, setBooks] = React.useState([]);
  const [bookcategories, setBookCategories] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalBooks, setTotalBooks] = useState(0);
  const [selectedBookId, setSelectedBookId] = useState();
  const [selectedBookCategoryName, setSelectedBookCategoryName] = useState();
  const [loading, setLoading] = useState(false);

  const fetchBooks = async () => {
    try {
      setLoading(true);
      const response = await bookService.getBooks({
        page: currentPage,
        search: searchTerm,
        genre: selectedBookCategoryName,
      });
      if (response.status === 200) {
        setBooks(response.data.data);
        setTotalPages(response.data.totalPages);
        setTotalBooks(response.data.totalBooks);
        setBooks(response.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchBookCategories = async () => {
    const response = await bookService.getBookCategories();
    if (response.status === 200) {
      setBookCategories(response.data);
    }
  };

  const openBookModalDetails = (bookId) => (e) => {
    if (!bookId) return false;
    setSelectedBookId(bookId);
    setOpen(true);
  };

  useEffect(() => {
    fetchBookCategories();
    return () => {};
  }, []);
  useEffect(() => {
    fetchBooks();
    return () => {};
  }, [currentPage, searchTerm, selectedBookCategoryName]);

  const isMobile = useResponsive("(max-width:767px)");
  return (
    <Page title="kontbox | Liste des livres">
      <Grid stackable>
        <Grid.Column width="8">
          <Input icon fluid placeholder="Rechercher ...">
            <input
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ borderRadius: "2rem" }}
            />
            <Icon name="search" />
          </Input>
        </Grid.Column>
        <Grid.Column width="8" textAlign="right">
          <Dropdown
            text={
              selectedBookCategoryName
                ? selectedBookCategoryName
                : "Tous les genres"
            }
            icon="filter"
            floating
            labeled
            button
            className="icon"
            style={{ minWidth: isMobile ? "100%" : "60%" }}
            scrolling
          >
            <Dropdown.Menu>
              <Dropdown.Header icon="tags" content="Filter by tag" />
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => setSelectedBookCategoryName(null)}>
                Tous les genres
              </Dropdown.Item>
              {bookcategories?.map((cat) => (
                <Dropdown.Item
                  onClick={() => setSelectedBookCategoryName(cat.name)}
                >
                  {cat.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Grid.Column>
      </Grid>

      {loading ? (
        <Loader
          active
          inline="centered"
          style={{
            marginTop: "100px",
            marginBottom: "20px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          Chagement en cours...
        </Loader>
      ) : (
        <Grid>
          <Grid.Column>
            {books?.length > 0 ? (
              <Grid stackable>
                <>
                  <Grid stackable>
                    {books?.map((book) => (
                      <BookInfosBox
                        book={book}
                        onClick={setOpen}
                        openBookModalDetails={openBookModalDetails}
                      />
                    ))}
                  </Grid>
                </>

                <Pagination
                  style={{ float: "right", marginTop: "20px" }}
                  defaultActivePage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(e, { activePage }) =>
                    setCurrentPage(activePage)
                  }
                />
              </Grid>
            ) : (
              <h3 style={{ textAlign: "center", marginTop: "100px" }}>
                Aucun livre trouvé
              </h3>
            )}
          </Grid.Column>
        </Grid>
      )}

      <BookModalDetails
        book={books?.find((book) => book.id === selectedBookId)}
        open={open}
        setOpen={setOpen}
      />
    </Page>
  );
};

export default BookList;
