import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Button, Form, Header, Message } from "semantic-ui-react";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <Helmet>
        <title>Réinitialisation du mot de passe</title>
      </Helmet>
      <Form className="login-form ui segment">
        <Header textAlign="center" as="h3" color="grey" style={{ marginBottom: 20}} >
          Réinitialisation du mot de passe
        </Header>

        <Form.Input
          label="Nouveau mot de passe"
          required
          action={{
            icon: showPassword ? "eye" : "eye slash",
            onClick: (e) => {
              e.preventDefault();
              setShowPassword(!showPassword);
            },
          }}
          type={showPassword ? "text" : "password"}
        />
        <Form.Input
          label="Confirmer mot de passe"
          required
          action={{
            icon: showPassword ? "eye" : "eye slash",
            onClick: (e) => {
              e.preventDefault();
              setShowPassword(!showPassword);
            },
          }}
          type={showPassword ? "text" : "password"}
        />
        <Form.Field>
          <Button
            icon="save"
            content="Enregistrer"
            fluid
            color="yellow"
            type="submit"
            className="submit"
          />
        </Form.Field>
        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Header as={Link} className="ui text yellow " to="/auth/login">
            Se connecter
          </Header>
        </div>
      </Form>
    </>
  );
};

export default ResetPassword;
