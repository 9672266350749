import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Segment } from "semantic-ui-react";

ChartJS.register(ArcElement, Tooltip, Legend);

function BestBooksChart({ data = [] }) {
  return (
    <Segment style={{marginTop: "15px"}}>
      <div className="ui dividing header">
        <h3> 5 Best Books</h3>
      </div>
      <div style={{ height: "400px" }}>
        <Pie
          redraw={true}
          data={{
            labels: data?.map((item) => item.name),
            datasets: [
              {
                label: "Views",
                data: data?.map((item) => item._count.Views),
                backgroundColor: [
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(75, 192, 192, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  "rgba(255, 159, 64, 0.2)",
                ],
                borderColor: [
                  "rgba(255, 99, 132, 1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(75, 192, 192, 1)",
                  "rgba(153, 102, 255, 1)",
                  "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 1,
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            height: "200px",
          }}
        />
      </div>
    </Segment>
  );
}

export default BestBooksChart;
