import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
body,html{
    font-family: "Source Sans Pro","Helvetica Neue",Helvetica,Arial,sans-serif !important;
font-size: 16px !important;
font-weight: 400 !important;
line-height: 24px !important;
color: #6f6f6f !important;
background-color: #fff !important;
}
#root{
    height: 100%;
}
.login-form{
    width: 70% !important;
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        max-width: 300px !important;
    width: 300px !important;
  }
    button.submit{
        border-radius: 1.5rem;
    }
}
.page-wrapper{
        margin-top: 3rem !important;
    }

    .pusher{
        @media(max-width: 767px){
            margin-left: 0;
        }
    }

.ui.sidebar{
        overflow: hidden !important ;
        z-index: 1500 !important;
        a.item{
           
            &:hover,&.active{
                color: #f9a826;
                border-left: 3.5px solid  #f9a826 !important;
            }
        }
    }

    @media(max-width: 767px){
           body,html{
               width: 100%;
               overflow-x: hidden;
           };
        }
`;
