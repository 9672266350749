import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import AuthLayout from "./components/shared/AuthLayout";
import DashboardLayout from "./components/shared/DashboardLayout";
import ChangePassword from "./pages/Auth/ChangePassword";
import ForgotPasswordPage from "./pages/Auth/ForgotPassword";
import LoginPage from "./pages/Auth/LoginPage";
import RegisterPage from "./pages/Auth/RegisterPage";
import ResetPassword from "./pages/Auth/ResetPassword";
import NotificationsIndex from "./pages/Notifications/NotificationsIndex";
import SubscriptionsIndex from "./pages/Subscriptions/SubscriptionsIndex";
import MessagesIndex from "./pages/Messages/MessagesIndex";
import { authState } from "./atoms/authState";
import { getUserFromStorage, removeUserData } from "./services/authService";
import ProfilePage from "./pages/Profile/ProfilePage";
import HomePage from "./pages/Home/HomePage";
import BookIndex from "./pages/Books/BookIndex";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import BookList from "./pages/Admin/Books/BookList";
import BookCategoriesList from "./pages/Admin/Categories/BookCategoriesList";
import DashboardPage from "./pages/Admin/Dashboard/DashboardPage";

function App() {
  const [authStore, setAuthStore] = useRecoilState(authState);

  useEffect(() => {
    const initAuth = () => {
      try {
        let userData = JSON.parse(getUserFromStorage());
        const decodedToken = jwt_decode(userData?.token);
        const today = dayjs();
        const tokenExpireAt = dayjs(decodedToken?.exp * 1000);
        if (today.diff(tokenExpireAt, "day") >= 0) {
          throw new Error("Your token as expired");
        }
        console.log(userData);
        setAuthStore(userData);
      } catch (error) {
        removeUserData();
        setAuthStore(null);
      }
    };
    initAuth();
    return () => {};
  }, []);
  return (
    <Routes>
      {!authStore?.token ? (
        <Route path="auth/*" element={<AuthLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="login" />} />
        </Route>
      ) : (
        <Route path="/*" element={<DashboardLayout />}>
          <Route path="books/*" element={<BookIndex />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="notifications" element={<NotificationsIndex />} />
          <Route path="messages" element={<MessagesIndex />} />
          <Route path="subscriptions" element={<SubscriptionsIndex />} />
          <Route path="*" element={<Navigate to="books" replace />} />
          {authStore?.user?.role?.includes("ADMIN") && (
            <>
              <Route path="admin/*">
                <Route index element={<DashboardPage />} />
                <Route path="books/*">
                  <Route path="*" element={<BookList />} />
                </Route>
                <Route path="categories/*">
                  <Route index element={<BookCategoriesList />} />
                </Route>
              </Route>
            </>
          )}
        </Route>
      )}

      <Route path="/" element={<HomePage />} />
      <Route path="*" element={<Navigate to="auth/login" />} />
    </Routes>
  );
}

export default App;
