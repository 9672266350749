import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import NotificationsList from "./NotificationsList";


const NotificationsIndex = () => {
  return (
      <Routes>
          <Route path="/" element={<NotificationsList/>}/>
      </Routes>
  );
};

export default NotificationsIndex;
