import React from "react";
import { Route, Routes } from "react-router-dom";
import BookDetails from "./BookDetails";
import BookList from "./BookList";
import BookRead from "./BookRead";

const BookIndex = () => {
  return (
    <Routes>
      <Route path="/" element={<BookList />} />
      <Route path="/:slug" element={<BookDetails />} />
      <Route path="/read/:slug" element={<BookRead />} />
    </Routes>
  );
};

export default BookIndex;
