import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Button, Form, Header, Message } from "semantic-ui-react";

const ForgotPasswordPage = () => {
  return (
    <>
      <Helmet>
        <title>Récupération du compte</title>
      </Helmet>
      <Form className="login-form ui segment">
        <Header textAlign="center" as="h3" color="grey">
          Récupération du compte
        </Header>
        <Message>
          <Message.Content>
            Entrez votre Adresse E-mail, nous vous enverons un lien qui vous
            permettra de réinitialiser votre mot de passe.
          </Message.Content>
        </Message>
        <Form.Input
          label="Email"
          required
          action={{ icon: "envelope" }}
          actionPosition="left"
        />
        <Form.Field>
          <Button
            icon="send"
            content="Valider"
            fluid
            color="yellow"
            type="submit"
            className="submit"
          />
        </Form.Field>
        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Header as={Link} className="ui text yellow " to="/auth/login">
            Se connecter
          </Header>
        </div>
      </Form>
    </>
  );
};

export default ForgotPasswordPage;
