import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Divider,
  Grid,
  Icon,
  Image,
  Loader,
  Segment,
} from "semantic-ui-react";
import Page from "../../components/shared/Page";
import TopReaderBar from "../../components/TopReaderBar";
import * as bookService from "../../services/bookService";
import * as chapterService from "../../services/chapterService";

const BookRead = () => {
  const [book, setBook] = useState();
  const [chapter, setChapter] = useState();
  const [currentChapterIdx, setCurrentChapterIdx] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(false);

  const { slug } = useParams();

  const fetchBook = async () => {
    const response = await bookService.getBookBySlug(slug);
    if (response.status === 200) {
      setBook(response.data);
      if (book?._count?.Chapter > 0) setCurrentChapterIdx(0);
    }
  };

  const fetchChapter = async () => {
    try {
      setLoading(true);
      if (book?._count?.Chapter === 0) return;
      if (book) {
        const chapterId = book?.Chapter[currentChapterIdx]?.id;
        const response = await chapterService.getChapter(chapterId);
        if (response.status === 200 && response.data instanceof Object) {
          setChapter(response.data);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBook();
    return () => {};
  }, []);

  useEffect(() => {
    fetchChapter();
    return () => {};
  }, [currentChapterIdx, book]);

  useEffect(() => {
    return () => {
      if ("speechSynthesis" in window) {
        window.speechSynthesis.cancel();
      }
    };
  }, []);

  return (
    <Page>
      <TopReaderBar
        book={book}
        activeChapter={currentChapterIdx}
        setCurrentChapterIdx={setCurrentChapterIdx}
      />
      <div
        className=""
        style={{
          marginTop: "2rem",
          display: "flex",
          alignItems: "center",
          gap: 15,
        }}
      >
        <Button.Group>
          <Button
            icon="play"
            onClick={() => {
              setIsPlaying(true);
              if ("speechSynthesis" in window) {
                if (window.speechSynthesis.speaking) {
                  window.speechSynthesis.resume();
                } else {
                  const utterance = new SpeechSynthesisUtterance(
                    removeChaptertextFromContent(chapter?.content)
                  );
                  utterance.lang = "fr-FR";
                  utterance.rate = 0.9;
                  window.speechSynthesis.speak(utterance);
                }
              } else {
                alert("Votre navigateur ne supporte pas la Web Speech API.");
              }
            }}
          />
          <Button
            icon="pause"
            onClick={() => {
              if ("speechSynthesis" in window) {
                window.speechSynthesis.pause();
              } else {
                alert("Votre navigateur ne supporte pas la Web Speech API.");
              }
            }}
          />
          <Button
            icon="stop"
            onClick={() => {
              if ("speechSynthesis" in window) {
                setIsPlaying(false);
                window.speechSynthesis.cancel();
              } else {
                alert("Votre navigateur ne supporte pas la Web Speech API.");
              }
            }}
          />
        </Button.Group>
        {isPlaying && (
          <div className="boxContainer">
            <div className="box box1"></div>
            <div className="box box2"></div>
            <div className="box box3"></div>
            <div className="box box4"></div>
            <div className="box box5"></div>
          </div>
        )}
      </div>
      <Divider />
      <Grid stackable style={{ marginTop: "1rem" }}>
        <Grid.Column width="12">
          <Segment basic>
            <div>
              {loading ? (
                <Loader
                  active
                  inline="centered"
                  style={{
                    marginTop: "100px",
                    marginBottom: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  Chargement en cours...
                </Loader>
              ) : (
                <pre
                  style={{
                    whiteSpace: "pre-wrap",
                    fontFamily: "sans-serif",
                    textAlign: "justify",
                  }}
                >
                  {chapter?.name && (
                    <h2>
                      Chapitre {currentChapterIdx + 1} : {chapter?.name}
                    </h2>
                  )}
                  {removeChaptertextFromContent(chapter?.content)}
                </pre>
              )}
            </div>
            <Grid stackable style={{ marginTop: "2rem" }}>
              <Grid.Column width="8">
                <Button
                  fluid
                  onClick={() => {
                    if (currentChapterIdx === 0) return;
                    setCurrentChapterIdx((previousIndex) => {
                      return currentChapterIdx - 1;
                    });
                    window.scrollTo(0, 0);
                    if ("speechSynthesis" in window) {
                      setIsPlaying(false);
                      window.speechSynthesis.cancel();
                    } else {
                      alert(
                        "Votre navigateur ne supporte pas la Web Speech API."
                      );
                    }
                  }}
                >
                  <Icon name="arrow alternate circle left" /> Chapitre précédent
                </Button>
              </Grid.Column>
              <Grid.Column width="8">
                <Button
                  fluid
                  onClick={() => {
                    const chaptersCount = book?._count?.Chapter;
                    if (chaptersCount === currentChapterIdx + 1) return;
                    setCurrentChapterIdx((previousIndex) => previousIndex + 1);
                    window.scrollTo(0, 0);
                    if ("speechSynthesis" in window) {
                      setIsPlaying(false);
                      window.speechSynthesis.cancel();
                    } else {
                      alert(
                        "Votre navigateur ne supporte pas la Web Speech API."
                      );
                    }
                  }}
                >
                  Chapitre Suivant <Icon name="arrow alternate circle right" />
                </Button>
              </Grid.Column>
            </Grid>
          </Segment>
        </Grid.Column>
        <Grid.Column width="4">
          <Segment basic>
            <div>
              <Image
                src={require("../../assets/images/_279cb498-6db2-480f-8647-bb321cf61356.jpg")}
                style={{ cursor: "pointer" }}
                centered
              />
            </div>
          </Segment>
          <Segment basic>
            <div>
              <Image
                src={require("../../assets/images/_30595ca2-7877-4ff4-958b-b89db66c8d31.jpg")}
                style={{ cursor: "pointer" }}
                centered
              />
            </div>
          </Segment>
          <Segment basic>
            <div>
              <Image
                src={require("../../assets/images/_ef8ac1ad-c282-4dd4-ae07-fbf8b350de4e.jpg")}
                style={{ cursor: "pointer" }}
                centered
              />
            </div>
          </Segment>
        </Grid.Column>
      </Grid>
    </Page>
  );
};

export default BookRead;

function removeChaptertextFromContent(content = "") {
  //check first line  contains "chapitre"
  const firstLine = content?.substring(0, content?.indexOf("\n"));
  if (firstLine.toLocaleLowerCase().includes("chapitre")) {
    return content?.substring(content?.indexOf("\n") + 1);
  }
  return content;
}
